import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/header/hero'
import Container from '../components/container'
import Newsletter from '../components/footer/newsletter'

export default ({data}) => {
  return (
    <Layout>
      <SEO title={data.wordpressPage.yoast_title} meta={data.wordpressPage.yoast_meta} path={data.wordpressPage.slug}/>
      <Hero
        mode="default"
        title={data.wordpressPage.acf.header_text}
        content={data.wordpressPage.acf.header_content}
        image={ data.wordpressPage.featured_media ?
          data.wordpressPage.featured_media.localFile.childImageSharp.fluid
        :
          null
        }
        button={
          {
            title: typeof data?.wordpressPage?.acf?.header_button?.title !== 'undefined' ? data.wordpressPage.acf.header_button.title : '',
            url: typeof data?.wordpressPage?.acf?.header_button?.url !== 'undefined' ? data.wordpressPage.acf.header_button.url : '',
            target: typeof data.wordpressPage.acf.header_button.target !== 'undefined' ? data.wordpressPage.acf.header_button.target : '',
          }
        }
      />
      <div className="partnersDonations">
        <Container>
          <div dangerouslySetInnerHTML={{ __html: data.wordpressPage.content}}/>
        </Container>
      </div>
      <Newsletter />
    </Layout>
  )
}
export const pageQuery = graphql`
query missionQuery {
  wordpressPage(wordpress_id: {eq: 122}) {
    acf {
      header_content
      header_text
      header_button {
        url
        title
        target
      }
    }
    content
    title
    slug
    yoast_meta {
      name
      property
    }
    yoast_title
    featured_media {
      source_url
      localFile {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1980) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
}

`